.form-btn {
  display: block;
  padding: 0.7rem 2rem;
  max-height: 3rem;
  outline: none;
  border: none;
  border-radius: 0.6rem;
  cursor: pointer;
  transform: translate(-0%);
  z-index: 0;
  span {
    font-size: 1.2rem;
    transform: scaleX(0.6);
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
  }
  &:before,
  &:after {
    content: '';
    height: 50%;
    width: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
  &:before {
    top: 0;
    left: 0;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    right: auto;
  }
  &:after {
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    bottom: 0;
    right: 0;
    left: auto;
  }
  &:hover {
    &:before {
      width: 100%;
      right: 0;
      left: auto;
    }
    &:after {
      width: 100%;
      left: 0;
      right: auto;
    }
  }
}

.form-btn.active {
  cursor: not-allowed;
  opacity: 0.2;

  &:before,
  &:after {
    display: none;
  }
}
