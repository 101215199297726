.selectorField {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.2rem;
  background: none;
  outline: none;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.selectorField-title {
  position: absolute;
  top: -0.75rem;
  left: 1rem;
  padding: 0 0.2rem;
  font-size: 1.1rem;
  font-weight: 500;
  transition: 0.2s;
}
.selectorField-placeholder {
  margin-left: 0.5rem;
}
.selectorField-icon {
  margin-right: 1rem;
  height: 1.5rem;
}
.select-list {
  width: 100%;
  border-radius: 0.5rem;
  max-height: 20rem;
  overflow-y: auto;
  position: absolute;
  z-index: 20;
}
.select-list.inactive {
  display: none;
}
.select-item {
  font-size: 1.2rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  list-style: none;
  cursor: pointer;
}
.select-item:last-of-type {
  border-bottom: none;
}
