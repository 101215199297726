* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: text;
}
html {
  font-family: 'Lato', Arial, sans-serif;
  font-size: 18px;
}
.fullscreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body-unauthenticated {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(29, 30, 32, 0.65), rgba(16, 12, 26, 0.75)),
    url('../img/luke-chesser-JKUTrJ4vK00-unsplash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  div {
    color: #f5f5f5;
    font-size: 4.5rem;
    margin-bottom: 5vh;
  }

  button {
    position: relative;
    color: #ececec;
    padding: 1rem 3.5rem;
    background-color: transparent;
    border: 2px solid #219cd5;
    border-radius: 2%;
    cursor: pointer;
    transition: ease-in-out 0.5s;
    font-size: 1.2rem;
    outline: none;
    z-index: 1;
  }

  button:hover {
    color: #ececec;
    border: 2px solid #f5f5f5;
  }

  button::before {
    content: '';
    position: absolute;
    transition: ease-in-out 0.3s;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    background-color: #219cd5;
  }
  button:hover::before {
    left: 0;
    right: 0;
    opacity: 2;
    z-index: -1;
  }
}
