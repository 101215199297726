.form-area-check-label {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  user-select: none;
}
.checkbox-item input[type='checkbox'] {
  display: none;
}
.checkbox-item {
  font-size: 1.2rem;
  list-style: none;
}
.checkbox-item:last-of-type {
  border-bottom: none;
}
.form-area-check-label::before {
  content: '';
  position: absolute;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  margin-right: 0.75rem;
  position: relative;
  transition: all 0.2s ease;
}
.form-area-check-label::after {
  content: '';
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  transform: translateX(40%) scale(0);
  border-radius: 50%;
}
.form-area-checkbox:checked ~ .form-area-check-label::after {
  transform: translateX(40%) scale(1);
}
