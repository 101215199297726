@use 'sass:math';
$shift: 0.5rem;

aside {
  min-height: 99vh;
  margin-right: $shift;
  position: sticky;
  --webkit-position: sticky;
  top: 0;
}

#expand_arrow {
  margin: 1rem 0 0 1rem;
  cursor: pointer;
}
.company_logo {
  display: flex;
  margin: 0.5rem auto 0 auto;
  transform: translateX(math.div($shift, 2));
  img {
    width: 180px;
    cursor: pointer;
  }
}

.user_dropdown {
  $dropdown-size: 2rem;
  margin: 2rem auto 0 auto;
  width: 85%;
  position: relative;
  ul {
    list-style: none;
  }

  .dropdown {
    height: $dropdown-size;
    cursor: pointer;
  }

  .dropdown_nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .dropdown_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdown_label {
    width: 100%;
    height: 100%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown_menu {
    position: absolute;
    top: ($dropdown-size + 0.005rem);
    width: 100%;
    overflow: hidden;
    a {
      text-decoration: none;
    }
  }

  .dropdown_item {
    height: $dropdown-size * 0.8;
    display: flex;
    align-items: center;
    transition: background 500ms;
    padding: 0.5rem 0.2rem;
    cursor: pointer;
  }

  .dropdown_logo {
    height: $dropdown-size * 0.7;
    width: $dropdown-size * 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 0.5rem;

    svg {
      height: $dropdown-size * 0.4;
      width: $dropdown-size * 0.4;
    }
  }
}
