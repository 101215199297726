footer {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);

  a {
    font-size: 0.6rem;
    text-decoration: none;

    pre {
      font-family: inherit;
    }
  }
}
