.navbar {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-left: 2rem;

  .nav-menu {
    display: flex;
    margin-right: 2rem;
    list-style: none;

    .nav-item {
      cursor: pointer;
      padding: 1rem 2rem;
      position: relative;
    }
  }
}

.nav-submenu {
  list-style: none;
  position: absolute;
  width: 100%;
  top: 3.15rem;
  left: 0;
  text-align: center;
  z-index: 2;
  li {
    cursor: pointer;

    a {
      display: block;
      padding: 1rem;
      text-decoration: none;
    }
  }
}

.nav-submenu.inactive {
  display: none;
}
