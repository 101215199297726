.load_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.spin {
  animation-name: spin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
